.bg-default {
  background: url(../image/Login/bg_default.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.bg_default {
  background: url(../image/Login/bg_default.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.icon {
  flex: 1;
  margin: auto auto;
}
.input-hidden:focus {
  border: none;
  outline-offset: 0px;
  outline: none;
}
.form__footer-logo {
  width: 120px;
}
.form__footer-copyright {
  line-height: 15px;
  width: 220px;
  height: 45px;
  font-family: Montserrat-Regular;
  font-weight: 500;
  font-size: 13px;
  margin-top: 14px;
}
.icon-infor-li {
  padding: 15px 15px 15px 20px;
}
.content-info-li {
  display: flex;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 13px;
  line-height: 20px;
}
.footer-infor {
  /* margin-bottom: 100px; */
}

@media (max-height: 730px) {
  .footer-infor {
    margin-top: 100px;
  }
}

@media (min-height: 800px) {
  .footer-infor {
    margin-top: 280px;
  }
}
@media (min-height: 900px) {
  .footer-infor {
    margin-top: 350px;
  }
}
