.guide-take-photo.modal-header {
  height: 100%;
}
.guide-take-photo .modal-body {
  padding-bottom: 1.25rem;
  position: relative;
  top: -30px;
}
.guide-take-photo .check {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
}
.guide-take-photo input::placeholder {
  color: #bdbdbd;
  font-size: 13px;
  font-family: Montserrat;
}

.webcam {
  margin-left: 0px;
  background-color: #333333;
  /* height: 100vh; */
}
.webcamcontent {
  /* margin-top: 80px; */
  border: 2px solid #5bc2dc;
  border-radius: 20px;
}
.btn-webcam {
  height: 80px;
  width: 80px;
  border-radius: 90px;
  margin-top: 4px;
  background-color: #5bc2dc;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}
.btn-cancel {
  height: 70px;
  border: 0px solid;
  width: 70px;
  border-radius: 18px;
  margin-right: 15px;
  text-align: center;
  margin-top: 10px;
  background-color: #1a9ad1;
  opacity: 1;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 14px;
}
.btn-gui {
  height: 40px;
  border: 0px solid;
  width: 110px;
  border-radius: 18px;
  margin-left: 10px;
  margin-top: 20px;
  background-color: #fee1b9;
  color: #5bc2dc;
  font-weight: 700;
}
.popup-box-cam {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  padding: 0px 0px 15px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #302d2d;
}
.title-description-bill {
  font-family: Montserrat-Semibold;
  margin-left: 2px;
  font-size: 13px;
  line-height: 18px;
  width: 80%;
  word-wrap: break-word;
  text-align: left;
}
.full-height {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.btn-takephoto {
  /* h-[60px] w-[140px]  rounded-2x */
  display: flex;
  justify-content: space-around;
  height: 55px;
  width: 140px;
  border-radius: 16px;
}
.content-description-bill {
  font-family: Montserrat-Light;
  line-height: 19px;
  font-size: 13px;
}
.btn-text {
  width: 80px;
  border-radius: 16px;
}
.carousel .control-dots {
  z-index: 0;
}
.btn-tc-detail {
  border-top: 1px solid;
  border-left: 1px solid;
  border-top-left-radius: 18px;
  padding-top: 5px;
  padding-left: 20px;
  background-color: #1f3ca3;
  width: 45%;
  height: 28px;
  color: #ffffff;
  font-size: 12px;
}
.input-name-staff {
  font-weight: 700;
  text-transform: uppercase;
  color: #0aaa52;
  font-size: 20px;
  margin-top: -45px;
}
.input-name-staff-form {
  border: 1px solid #0aaa52;
}
.input-btn-staff {
  border: 1px solid #0aaa52;
  width: 100px;
  height: 40px;
  background-color: #0aaa52;
  border-radius: 10px;
  color: #ffffff;
}
.input-btn-staff-cancel {
  border: 1px solid #fff;
  width: 100px;
  height: 40px;
  background-color: #63cafd;
  border-radius: 10px;
  color: #333333;
}
.bg-guide {
  background: url(../../assets/image/TakeAPhoto/header_popup_phone.png);
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100%;
  top: -20px;
}
.header-info {
  font-size: 20px;
}
.bg-bottom-guide {
  position: relative;
  top: 6%;
  bottom: 0;
  width: 100vw;
  max-height: 100%;
}
.logo-ios {
  background-image: url(../../assets/image/TakeAPhoto/ios.svg);
  width: 25px;
  height: 28px;
  background-repeat: no-repeat;
}
.logo-android {
  background-image: url(../../assets/image/TakeAPhoto/android.svg);
  width: 25px;
  height: 28px;
  background-repeat: no-repeat;
}

.header-logo {
  font-family: Montserrat-Semibold;
  color: #828282;
  font-size: 13px;
  height: 40;
}

.style-carousel {
  height: 324px;
  margin-top: 16px;
}
.btn-next {
  margin-top: 12px;
  height: 15px;
}
.location-btn {
}
.text-btn-cancel {
  font-size: 13px;
}
.description-guide {
  margin-top: 11px;
  /* flex: 5; */
}
.body-description {
}
.title-description {
  font-family: Montserrat-Semibold;
  margin-left: 10px;
  font-size: 13px;
  line-height: 18px;
  width: 80%;
  word-wrap: break-word;
  text-align: left;
}
.content-description {
  font-family: Montserrat-Light;
  line-height: 19px;
}

@media (max-width: 336px) {
  .location-btn {
    margin-top: 260px;
    margin-bottom: 50px;
  }
}

.webcam {
  margin-left: 0px;
  background-color: #333333;
  /* height: 100vh; */
}
.webcamcontent {
  /* margin-top: 80px; */
  border: 2px solid #f89f2a;
  border-radius: 20px;
}
.btn-webcam {
  height: 70px;
  width: 70px;
  border-radius: 70px;
  margin-top: 10px;
  border: 0px solid;
  background-color: #5bc2dc;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}
.select-cam-phone {
  width: 120px;
  height: 50px;
  margin-top: 12px;
  border-radius: 18px;
  margin-right: 15px;
  background-color: #fce2c1;
  color: #f89f2a;
  border: 0px solid #f89f2a;
}
.bg-button-camera {
  position: absolute;
  bottom: 0px;
  z-index: 100;
  width: 100%;
  height: 130px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.btn-cancel {
  height: 50px;
  width: 100px;
  border-radius: 18px;
  margin-right: 15px;
  margin-top: 10px;
  background-color: #fce2c1;
  color: #f89f2a;
  font-weight: 700;
  border: 0px solid #f89f2a;
  font-size: 20px;
  line-height: 14px;
}
.btn-gui {
  height: 40px;
  border: 0px solid;
  width: 110px;
  border-radius: 18px;
  margin-left: 10px;
  margin-top: 20px;
  background-color: #fee1b9;
  color: #fea32b;
  font-weight: 700;
}
.popup-box-cam {
  /* border-radius: 20px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  /* margin-top: 0px; */
  /* transform: translateY(-28%); */
  padding: 0px 0px 15px 0px;
  /* margin-top: 10px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  background-color: #ffffff;
}
/* .popup-box-cam:before {
  content: "";
  background: url("../images/tick_icon.png");
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  background-repeat: no-repeat;
  background-size: contain;
} */
.popup-box-cam .close-btn {
  cursor: pointer;
  position: absolute;
  top: -10px;
  /* right: -8px; */
  width: 35px;
}
.title-cam {
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  line-height: 20px;
}
.content-cam {
  margin-top: 15px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 200;
  text-align: center;
  line-height: 20px;
  width: 100%;
}
.img-cam {
  width: 40px;
  height: 40px;
  color: #ffffff;
  margin-left: 2px;
  margin-right: 4px;
  margin-top: 4px;
}
.img-cam-only {
  width: 80px;
  height: 80px;
  color: #ffffff;
  /* margin-left: 2px;
  margin-right: 4px;
  margin-top: 4px; */
  margin-top: 8px;
  font-size: 40px;
}
.cam-hd {
  margin-left: 8px;
  font-weight: 400;
  font-size: 19px;
}
.cam-hd1 {
  margin-left: 12px;
  font-weight: 500;
  font-size: 19px;
}
.load {
  background-image: url(../../assets/image/TakeAPhoto/upload.gif);
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.style-choose {
  font-size: 12px;
}
.help .fade {
  padding-left: -10px;
}
.color-button-primary {
  background-color: #f3ab1f;
}
.color-button-disable {
  background-color: #bdbdbd;
}
.color-button-enable {
  background-color: #16a5a3;
}
.color-button-blue {
  background-color: #16a5a3;
}
.button-login {
  padding: 13px 48px;
  border-radius: 13px;
  font-size: 16px;
}
