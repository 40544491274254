.font-italic-mon {
  font-family: Montserrat-Italic;
}
.font-thin-mon {
  font-family: Montserrat-Thin;
}
.font-bold-mon {
  font-family: Montserrat;
}
.font-semibold-mon {
  font-family: Montserrat-SemiBold;
}
.font-regular-mon {
  font-family: Montserrat-Regular;
}
.font-light-mon {
  font-family: Montserrat-Light;
}

.font-bebas-mon {
  font-family: UTM-Bebas;
}
.font-bold-go {
  font-family: SVN-GothamBold;
}
.font-black-go {
  font-family: SVN-GothamBlack;
}
.font-regular-go {
  font-family: SVN-GothamRegular;
}
@font-face {
  font-family: "UTM-Bebas";
  src: url("../../assets/fonts/UTM-Bebas.ttf");
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url("../../assets/fonts/Montserrat-Italic.ttf");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url("../../assets/fonts/Montserrat-Thin.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../../assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("../../assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "SVN-GothamBold";
  src: url("../../assets/fonts/SVN-GothamBold.ttf");
}

@font-face {
  font-family: "SVN-GothamBlack";
  src: url("../../assets/fonts/SVN-GothamBlack.otf");
}

@font-face {
  font-family: "SVN-GothamRegular";
  src: url("../../assets/fonts/SVN-GothamRegular.otf");
}
