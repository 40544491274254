.success {
  background-image: url(../image/Game/success_bg.png);
  background-position: center;

  background-repeat: no-repeat;
}
.success-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  gap: 4px;
  border: 1px solid #00a1df;
  border-radius: 16px;
  font-size: 13px;
}
.ani-title {
  animation: fadeIn 1s linear;
}
.ani-gift {
  animation: fadeIn 0.8s linear, fadeRotate 0.5s linear infinite alternate 1s;
}
.ani-img {
  animation: fadeImage 1s linear;
}
@keyframes fadeImage {
  from {
    transform: translateY(-110%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fadeRotate {
  from {
    transform: rotate(-2deg);
  }
  to {
    transform: rotate(2deg);
  }
}
@keyframes fadeIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.bg-success {
  background-image: url(../image/Game/bg-success.png);
  min-height: 100vh;
  background-size: cover;
}
.bg-gifttop {
  background-image: url(../image/Game/bg-success-gifttop.png);
  background-repeat: no-repeat;
  width: 140px;
  height: 250px;
  background-size: 250px;
  background-position: center;
}
.containerSuccess__background {
  background: transparent;
  height: 85vh;
  overflow: hidden;
}
.containerSuccess__background-list {
  height: 100%;
}
.containerSuccess__background .containerSuccess__background-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.ani-guide {
  animation: fadeGuide 2s ease-in;
}

@keyframes fadeGuide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animation-success {
  background: url(../image/Game/animation.gif);
  background-size: 100%;

  background-position: cover;
}
